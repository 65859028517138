<template>
  <section class="content info-box-section">
    <div class="alert" v-bind:class="workedAlertClass">
      <button
        type="button"
        class="close"
        data-dismiss="alert"
        aria-hidden="true"
        v-if="dismissible"
        v-on:click="onClose"
      >
        ×
      </button>
      <h3>
        <i v-if="workedIconClass" v-bind:class="workedIconClass"></i>
        {{ workedTitle }}
      </h3>
      <p>{{ workedText }}</p>
    </div>
  </section>
</template>

<script>
export default {
  name: "InfoBox",
  props: {
    preset: {
      type: String,
      default: ""
    },
    title: {
      type: String,
      default: ""
    },
    text: {
      type: String,
      default: ""
    },
    alert: {
      type: String,
      default: "info"
    },
    icon: {
      type: String,
      default: ""
    },
    dismissible: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    onClose() {
      this.$emit("close");
    }
  },
  computed: {
    alertClass: function() {
      switch (this.preset) {
        case "unauthorized":
          return "info";
        default:
          return this.alert;
      }
    },
    iconClass: function() {
      switch (this.preset) {
        case "unauthorized":
          return "fa fa-lock";
        default:
          return this.icon;
      }
    },
    workedAlertClass: function() {
      switch (this.alertClass) {
        case "warning":
          return "alert-warning";
        case "default":
          return "alert-default";
        case "success":
          return "alert-success";
        case "danger":
          return "alert-danger";
        default:
          return "alert-info";
      }
    },
    workedIconClass: function() {
      if (this.iconClass) {
        var ret = {};
        this.iconClass.split(" ").forEach(function(cls) {
          if (cls) {
            ret[cls] = true;
          }
        });
        return ret;
      }
      return "";
    },
    workedTitle: function() {
      switch (this.preset) {
        case "unauthorized":
          return this.$t("unauthorized");
        default:
          return this.title;
      }
    },
    workedText: function() {
      switch (this.preset) {
        case "unauthorized":
          return this.$t("you_do_not_have_access_to_the_requested_resource");
        default:
          return this.text;
      }
    }
  }
};
</script>

<style scoped>
.info-box-section {
  border-radius: 10px;
  min-height: 0;
  padding: 10px;
}
.alert-info {
  color: #31708f !important;
  background-color: #f5f5f5 !important;
  border-color: #e4e4e4;
  opacity: 0.8;
}
.alert-info:hover {
  cursor: not-allowed;
  opacity: 1;
  box-shadow: none;
}
</style>
